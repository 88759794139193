import { AxiosResponse } from "axios";
import { CANCELLED_REQUEST_MESSAGE, CANCELLED_REQUEST_STATUS } from "../utils";

export default class StandardResponse<T> {
  public status: number;
  public code: number;
  public data: T;
  public messages: string;
  public exception: string | null;
  success: StandardResponse<any>;

  constructor(
    status: number,
    code: number,
    data: any,
    messages: string,
    exception: string | null
  ) {
    this.status = status;
    this.code = code;
    this.data = data;
    this.messages = messages;
    this.exception = exception;
  }
}

export const standardResponse = (res: AxiosResponse) => {
  if(res){
  const data = res.data.data ?? res.data;
  return new StandardResponse<any>(
    res.status,
    res.data.code,
    data,
    res.data.message,
    null
  );
  }
  return new StandardResponse<any>(
    CANCELLED_REQUEST_STATUS,
    CANCELLED_REQUEST_STATUS,
    null,
    CANCELLED_REQUEST_MESSAGE,
    null
  );
};
